import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import ContactInfo from "../components/Contact/ContactInfo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const Contact = () => {
  const {t} = useTranslation();
    return (
        <Layout>

            <Navbar />

            <PageBanner
                pageTitle={t('navBar.contact')}
                homePageText={t('navBar.home')}
                homePageUrl="/"
                activePageText={t('navBar.contact')}
            />

            <ContactInfo />

            {/*<ContactForm />*/}

            <Footer />

        </Layout>
    );
}

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "contact"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
