import React from 'react'
import { Link, Trans } from "gatsby-plugin-react-i18next";

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    {/*<div className="col-lg-4 col-md-6">*/}
                    {/*    <div className="contact-info-box">*/}
                    {/*        <div className="back-icon">*/}
                    {/*            <i className='bx bx-map'></i>*/}
                    {/*        </div>*/}
                    {/*        <div className="icon">*/}
                    {/*            <i className='bx bx-map'></i>*/}
                    {/*        </div>*/}
                    {/*        <h3><Trans i18nKey="contact:contactInfo.ourAddress">Our Address</Trans></h3>*/}
                    {/*        <p>Al. Komisji Edukacji Narodowej 36/112B</p>*/}
                    {/*        <p>02-793 Warszawa</p>*/}
                    {/*        <p>Polska</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3><Trans i18nKey="contact:contactInfo.contactUs">Contact Us</Trans></h3>
                            <p><Trans i18nKey="contact:contactInfo.mobile">Mobile</Trans>: <a href="tel:+48602226038">(+48) 602 226 038</a></p>
                            <p><Trans i18nKey="contact:contactInfo.email">E-mail</Trans>: <a href="mailto:contact@ecommercebox.io">contact@ecommercebox.io</a></p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3><Trans i18nKey="contact:contactInfo.hoursOfOperation">Hours of Operation</Trans></h3>
                            <p><Trans i18nKey="contact:contactInfo.mondayFriday">Monday - Friday</Trans>: 09:00 - 18:00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo
